import Link from 'next/link';
import { useRouter } from 'next/router';
import { SessionUtils } from '../../../helpers/utilities';
import { PushDataToGTM } from '../../../helpers/utilities/gtm';
import { Locales } from '../../../types/enums';
import { GTMEvents, GTMSubEvents } from '../../../types/gtm';
interface LocaleSwitcherProps {
  pageName: string;
}
const LocaleSwitcher: React.FC<LocaleSwitcherProps> = ({ pageName }) => {
  const router = useRouter();
  const { pathname, query, asPath, locales, locale: activeLocale } = router;
  // const languageRef = useRef<HeaderDropdownMenuService>(null);

  const otherLocales = locales?.filter(
    (locale) => locale !== activeLocale && locale !== 'default'
  );

  const handleLanguageChange = () => {
    // languageRef.current?.closeDropdown();

    const user = SessionUtils.getUserDetails();
    //Added GTM event for Compare Click
    PushDataToGTM(GTMEvents.LanguageSwitched, {
      userId: user?.UserId
        ? process.env.NEXT_PUBLIC_GTM_ENV_PREFIX! + user?.UserId
        : null,
      languageId:
        // added condition because router.locale updates late

        router.locale === Locales.EN ? Locales.AR : Locales.EN,
    });
  };

  return (
    <>
      {activeLocale === 'ar' ? (
        <Link href={{ pathname, query }} as={asPath} locale={'en'}>
          <div
            tabIndex={12}
            onClick={() => {
              PushDataToGTM(GTMEvents.DataLayerUpdate, {
                pageName: pageName,
                pageCategory: pageName,
                eventCategory: GTMSubEvents.Click,
                eventLabel: `${pageName} > ${'EN'}`,
              });
            }}
          >
            {/* <div className="lg:block hidden"> */}
            <div
              className={`cursor-pointer lang-linear-gradient flex items-center justify-between h-[28px] gap-[0.3125rem] w-full  border border-[#F0F0F0] py-[5px] px-[7px] rounded-[6px]`}
            >
              <picture>
                <img
                  src="/images/en-icon.png"
                  width={19.91}
                  height={20}
                  alt="icon"
                  loading="eager"
                  decoding="sync"
                  className="object-contain"
                />
              </picture>
              <span className="uppercase font-[13px] text-[#212121] font-medium">
                EN
              </span>
            </div>
          </div>
          {/* <span className="cursor-pointer lang-linear-gradient font-[13px] text-[#212121] font-medium border border-[#F0F0F0] p-[5px] rounded-[6px]">
              En
            </span> */}
          {/* </div> */}
        </Link>
      ) : (
        <Link href={{ pathname, query }} as={asPath} locale={'ar'}>
          <div
            tabIndex={13}
            onClick={() => {
              PushDataToGTM(GTMEvents.DataLayerUpdate, {
                pageName: pageName,
                pageCategory: pageName,
                eventCategory: GTMSubEvents.Click,
                eventLabel: `${pageName} > ${'عربی'}`,
              });
            }}
          >
            {/* <div className="lg:block hidden"> */}
            <div
              className={`cursor-pointer lang-linear-gradient flex items-center justify-between h-[28px] gap-[0.3125rem] w-full border border-[#F0F0F0] py-[5px] px-[7px] rounded-[6px]`}
            >
              <picture>
                <img
                  src="/images/ar-icon.png"
                  width={27.43}
                  height={18}
                  alt="icon"
                  className="object-contain"
                  loading="eager"
                  decoding="sync"
                />
              </picture>
              <span className="uppercase">عربى</span>
            </div>
          </div>
          {/* <span className="cursor-pointer lang-linear-gradient text-[#212121] font-[13px] font-medium border border-[#F0F0F0] p-[5px] rounded-[6px]">
              عربى
            </span> */}
          {/* </div> */}
        </Link>
      )}
      {/* <button
        className={`flex items-center justify-between h-8 gap-[0.3125rem] w-full border-none `}
        onClick={() => {
          handleLanguageChange();
        }}
      >
        <picture>
          <img src="/images/ar-icon.png" alt="icon" className="my-1" />
        </picture>

        <span className="uppercase rtl:pt-[0.25rem]">
          {activeLocale === 'ar'
            ? 'English'
            : activeLocale === 'en'
            ? 'عربى'
            : 'English'}
        </span>

        <ExpandFilledArrowIcon className="w-3 text-white" />
      </button> */}

      {/* <HeaderDropdownMenu
        ref={languageRef}
        image="/images/ar-icon.png"
        label={
          activeLocale === 'ar'
            ? 'English'
            : activeLocale === 'en'
            ? 'عربى'
            : 'English'
        }
        className={`${activeLocale === 'en' ? "font-['Vazirmatn']" : ''}`}
      >
        {() => (
          <ul className="flex flex-col w-[100px] divide-y">
            {locales?.map((locale) => {
            const { pathname, query, asPath } = router;
            return (
              <Link
                href={{ pathname, query }}
                as={asPath}
                locale={locale}
                key={locale}
              >
                <li
                  className={`header-dropdown-item ${
                    activeLocale === locale ? 'active' : ''
                  }`}
                  onClick={() => {
                    handleLanguageChange();
                  }}
                >
                  {locale === 'ar' ? (
                    <a className="w-full uppercase text-right font-['Vazirmatn']">
                      عربى
                    </a>
                  ) : (
                    <a className="w-full uppercase text-left">English</a>
                  )}
                </li>
              </Link>
            );
          })}
          </ul>
        )}
      </HeaderDropdownMenu> */}
    </>
  );
};
export default LocaleSwitcher;
