import { FC, ReactElement } from 'react';
import { SubMenuArrow } from '../../icons';
import { SubMenuItemData } from '../../../types/models';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

type propTypes = {
  menuData: SubMenuItemData;
};

const SubMenuItem: FC<propTypes> = (props): ReactElement => {
  const { i18n } = useTranslation();

  const {
    menuData: {
      isHide = false,
      title = '',
      link = '#',
      isImage = false,
      shortDescription = '',
      logo = '/images/gogo-brand/3.svg',
    },
  } = props;

  return isHide ? (
    <></>
  ) : (
    <>
      <Link href={link}>
        <a>
          <div className="sub-menu-item">
            <div className="flex justify-between">
              <div>
                <h2 className="flex gap-2 items-center text-[#3A3A3C] text-[15px] leading-[22px] font-semibold pb-[10px]">
                  {title}{' '}
                  <SubMenuArrow
                    className={`${i18n.language === 'ar' ? 'rotate-180' : ''}`}
                  />
                </h2>
                <div className="text-[#757575] text-[11px] leading-[16px] font-normal w-[217px]">
                  {shortDescription}
                </div>
              </div>
              {isImage && (
                <picture>
                  <img
                    src={!logo ? '/images/gogo-brand/3.svg' : ''}
                    alt="gogo-brand-logo"
                    loading="lazy"
                    width={43.63}
                    height={48.76}
                  />
                </picture>
              )}
            </div>
          </div>
        </a>
      </Link>
    </>
  );
};

export default SubMenuItem;
