import { useRouter } from 'next/router';
import { FC, ReactElement } from 'react';
import { useAppDispatch } from '../../../lib/hooks';
import { setCanonicalUrl } from '../../../lib/global.slice';
import IdentifierService from '../../../helpers/services/identifier.service';
import { SellMyCarPathname } from '../../../types/constants';
import {
  PushDataToGTM,
  PushSCEventsToGTM,
} from '../../../helpers/utilities/gtm';
import {
  GTMEvents,
  GTMSubEvents,
  PageNames,
  SellMyCarEvents,
} from '../../../types/gtm';
import SubMenuItem from './SubMenuItem';
import { MenuItem } from '../../../types/models';

type propTypes = {
  menuData: MenuItem;
  isStickyHeader?: boolean;
};

const Menu: FC<propTypes> = (props): ReactElement => {
  const {
    menuData: {
      isHide = false,
      title = '',
      link = '',
      column = 1,
      isImage = false,
      isSubMenuHide = false,
      subMenu = [],
    },
    isStickyHeader = false,
  } = props;
  const router = useRouter();
  const dispatch = useAppDispatch();

  return isHide ? (
    <></>
  ) : (
    <>
      <div className="h-full z-50 relative main-menu">
        <div
          onClick={async () => {
            if (link) {
              const clarityId = await IdentifierService.getClarityUserId();
              if (link && link.includes(SellMyCarPathname)) {
                PushSCEventsToGTM(SellMyCarEvents.CTA_CLICKED, {
                  clarity_id: clarityId,
                  button_location: 'Desktop header menu',
                });
              }
              PushDataToGTM(GTMEvents.DataLayerUpdate, {
                pageName: PageNames.Desktop_Menu,
                pageCategory: PageNames.Desktop_Menu,
                eventCategory: GTMSubEvents.Click,
                eventLabel: `${
                  PageNames.Desktop_Menu
                } > ${title.toUpperCase()}`,
              });
              router.push(link);
              dispatch(
                setCanonicalUrl(
                  `https://${process.env.NEXT_PUBLIC_APP_DOMAIN}${
                    router.locale === 'ar' ? '' : '/en'
                  }${URL}`
                )
              );
            }
          }}
          className={`menu-tag cursor-pointer ${
            isStickyHeader ? 'lg:h-[73px]' : 'lg:h-12'
          } inline-flex text-15 items-center text-gray hover:text-gray-900`}
        >
          {title}
        </div>
        {!isSubMenuHide && (
          <div
            className={`sub-menu absolute bg-[#FFFFFF] rounded-b-[22px] top-[calc(100%)] p-[20px] min-h-[124px] ${
              column === 1
                ? isImage
                  ? 'w-[363px]'
                  : 'w-[277px]'
                : isImage
                ? 'w-[726px]'
                : 'w-[544px]'
            } shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)]`}
          >
            <div
              className={`grid  grid-cols-${column === 1 ? 1 : 2} gap-[16px]`}
            >
              {subMenu.map((item, index) => (
                <SubMenuItem key={index} menuData={item} />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;
